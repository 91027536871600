import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'

export default function Chip(theme: Theme): Components['MuiChip'] {
  return {
    defaultProps: {
      size: 'medium'
    },
    styleOverrides: {
      root: {
        fontFamily: 'inherit',
        cursor: 'pointer',
        borderRadius: '50px',
        '&.Mui-disabled': {
          opacity: 1,
          border: `1px solid ${theme.palette.divider} !important`,
          backgroundColor: `${theme.palette.neutral[100]} !important`,
          span: {
            color: `${theme.palette.text.primary} !important`
          }
        }
      },
      outlined: {
        userSelect: 'none',
        border: `1px solid ${theme.palette.neutral[200]}`,
        padding: '4px 8px',
        '&:hover': {
          backgroundColor: theme.palette.neutral[100]
        },
        '&:active': {
          border: `1px solid ${theme.palette.neutral[300]}`,
          backgroundColor: theme.palette.neutral[300]
        },
        '.MuiChip-label': {
          fontFamily: 'inherit',
          padding: 0,
          color: theme.palette.neutral[900],
          fontSize: '14px'
        },
        '&.MuiChip-outlinedError': {
          border: `1px solid ${theme.palette.error.main}`,
          '&:hover': {
            backgroundColor: theme.palette.error[100]
          },
          '&:active': {
            backgroundColor: theme.palette.error.main
          },
          '&:active .MuiChip-label': {
            color: theme.palette.neutral['000']
          },
          '.MuiChip-label': {
            color: theme.palette.error.main
          }
        },
        '&.MuiChip-outlinedSuccess': {
          border: `1px solid ${theme.palette.success.main}`,
          '&:hover': {
            backgroundColor: theme.palette.success[100]
          },
          '&:active': {
            backgroundColor: theme.palette.success.main
          },
          '&:active .MuiChip-label': {
            color: theme.palette.neutral['000']
          }
        },
        '&.MuiChip-outlinedInfo': {
          border: `1px solid ${theme.palette.secondary[700]}`,
          '&:hover': {
            backgroundColor: theme.palette.secondary.main
          },
          '&:active': {
            backgroundColor: theme.palette.secondary[700]
          },
          '&:active .MuiChip-label': {
            color: theme.palette.neutral['000']
          }
        },
        '&.MuiChip-colorPrimary': {
          border: `1px solid ${theme.palette.secondary[600]}`,
          span: {
            color: theme.palette.primary.main
          },
          '&:hover': {
            backgroundColor: theme.palette.secondary[200]
          },
          '&:active': {
            backgroundColor: theme.palette.secondary[600]
          }
        }
      },
      filled: {
        userSelect: 'none',
        backgroundColor: theme.palette.neutral[100],
        padding: '5px 9px',
        '&:hover': {
          backgroundColor: theme.palette.neutral[200]
        },
        '&:active': {
          backgroundColor: theme.palette.neutral[300]
        },
        '.MuiChip-label': {
          fontFamily: 'inherit',
          padding: 0,
          color: theme.palette.neutral[900],
          fontSize: '12px'
        },
        '&.MuiChip-filledError': {
          backgroundColor: theme.palette.error[100],
          '&:hover': {
            backgroundColor: theme.palette.error[200]
          },
          '&:hover .MuiChip-label': {
            color: theme.palette.neutral['000']
          },
          '&:active': {
            backgroundColor: theme.palette.error.main
          },
          '&:active .MuiChip-label': {
            color: theme.palette.neutral['000']
          },
          '.MuiChip-label': {
            color: theme.palette.error.main
          }
        },
        '&.MuiChip-filledSuccess': {
          backgroundColor: theme.palette.success[100],
          '&:hover': {
            backgroundColor: theme.palette.success[200]
          },
          '&:active': {
            color: theme.palette.neutral[900],
            backgroundColor: theme.palette.success.main
          },
          '&:active .MuiChip-label': {
            color: theme.palette.neutral['000']
          }
        },
        '&.MuiChip-filledInfo': {
          backgroundColor: theme.palette.secondary[600],
          '&:hover': {
            backgroundColor: theme.palette.secondary[700]
          },
          '&:hover .MuiChip-label': {
            color: theme.palette.neutral['000']
          },
          '&:active': {
            backgroundColor: theme.palette.secondary[800],
            '.MuiChip-label': {
              color: theme.palette.neutral[900]
            }
          },
          '&:active .MuiChip-label': {
            color: theme.palette.neutral['000']
          }
        }
      }
    }
  }
}
