import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'
import Chevron from '../../icons/Chevron'

export default function Breadcrumbs(theme: Theme): Components['MuiBreadcrumbs'] {
  return {
    defaultProps: {
      separator: Chevron({ width: '16px', height: '16px', rotate: '-90deg' })
    },
    styleOverrides: {
      root: {
        '.MuiBreadcrumbs-separator': {
          fill: theme.palette.neutral.main
        },
        '.MuiBreadcrumbs-li': {
          lineHeight: '18.5px',
          textAline: 'center',
          a: {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            fontSize: '14px'
          }
        }
      }
    }
  }
}
