import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'

export default function TextField(theme: Theme): Components['MuiTextField'] {
  return {
    defaultProps: {
      variant: 'filled',
      InputProps: {
        endAdornment: null
      }
    },
    styleOverrides: {
      root: {
        width: '100%',
        backgroundColor: 'transparent',
        '.MuiFormLabel-root': {
          transition: '0.2s',
          transform: 'translate(16px, 19px) scale(1)',
          fontSize: '16px',
          width: 'calc(100% - 24px)',
          backgroundColor: 'transparent',
          color: theme.palette.neutral.main,
          fontFamily: 'inherit',
          '&.MuiInputLabel-shrink': {
            fontSize: '12px',
            paddingTop: '10px',
            transform: 'translate(12px, 1px) scale(1)',
            color: theme.palette.neutral.main
          },
          '&.MuiFormLabel-colorError': {
            color: theme.palette.error.main
          },
          '.MuiInputLabel-asterisk': {
            color: theme.palette.error.main
          }
        },
        '.MuiFilledInput-root': {
          '&.Mui-disabled': {
            backgroundColor: theme.palette.neutral[100],
            '&:hover': {
              backgroundColor: theme.palette.neutral[100]
            }
          },
          overflow: 'hidden',
          height: '60px',
          borderRadius: '10px',
          border: `1px solid ${theme.palette.neutral[200]}`,
          input: {
            height: '100%',
            backgroundColor: 'transparent',
            paddingTop: '20px',
            paddingRight: '16px',
            paddingBottom: '6px',
            paddingLeft: '12px',
            fontSize: '16px',
            fontFamily: 'inherit',
            color: theme.palette.neutral[900],
            '&:selected': {
              backgroundColor: 'transparent'
            },
            '&.Mui-disabled': {
              color: theme.palette.neutral.main
            }
          },
          textarea: {
            color: theme.palette.neutral[900],
            fontSize: '16px'
          },
          '&.Mui-focused': {
            border: `1px solid ${theme.palette.secondary.main}`,
            backgroundColor: 'transparent'
          },
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent'
          },
          '&:after': {
            borderBottom: 'none'
          },
          '&.MuiInputBase-colorError': {
            border: `1px solid ${theme.palette.error.main}`
          }
        },
        '.MuiInput-underline': {
          backgroundColor: 'transparent',
          overflow: 'hidden',
          width: 'fill',
          color: theme.palette.neutral[900],
          svg: {
            marginRight: '15px'
          },
          '&::after': {
            borderBottom: 'none'
          },
          input: {
            lineHeight: '20px',
            padding: '0',
            fontSize: '16px',
            '&::placeholder': {
              color: theme.palette.neutral.main,
              fontSize: '16px',
              lineHeight: '20px',
              opacity: 1
            }
          }
        }
      }
    }
  }
}
