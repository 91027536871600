import { Theme } from '@mui/material/styles/createTheme'

export default function Typography(theme: Theme) {
  return {
    htmlFontSize: 14,
    component: 'p',
    fontFamily: 'inherit',
    fontSize: 14,
    fontWeightLight: 200,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: 'PT Serif, serif',
      fontWeight: 700,
      fontSize: '48px',
      color: theme.palette.neutral[900]
    },
    h2: {
      fontFamily: 'PT Serif, serif',
      fontWeight: 700,
      fontSize: '28px',
      color: theme.palette.neutral[900],
      [theme.breakpoints.down('sm')]: {
        fontSize: '21.36px'
      }
    },
    h3: {
      fontFamily: 'PT Serif, serif',
      fontSize: '24px',
      fontWeight: 400,
      color: theme.palette.neutral[600]
    },
    h4: {
      fontSize: '16.88px',
      fontWeight: 500
    },
    h5: {
      fontFamily: 'PT Serif, serif',
      fontSize: '14px',
      fontWeight: 400,
      color: theme.palette.neutral[600]
    },
    h6: {
      fontFamily: 'PT Serif, serif',
      fontSize: '12px',
      fontWeight: 400,
      color: theme.palette.neutral[600]
    },
    body1: {
      fontFamily: 'inherit',
      wordSpacing: '1px',
      color: theme.palette.text.primary
    },
    body2: {
      fontFamily: 'inherit',
      fontSize: '16px',
      lineHeight: '24px',
      color: theme.palette.text.primary,
      wordSpacing: '1px'
    },
    subtitle1: {
      fontFamily: 'inherit',
      fontSize: '16px',
      fontWeight: 400,
      color: theme.palette.text.secondary,
      lineHeight: '24px'
    },
    subtitle2: {
      fontFamily: 'inherit',
      fontSize: '14px',
      fontWeight: 500,
      color: theme.palette.text.secondary,
      lineHeight: '21px'
    },
    button: {
      fontFamily: 'inherit',
      fontSize: '14px',
      fontWeight: 500,
      color: theme.palette.primary.main
    },
    caption: {
      fontFamily: 'inherit',
      fontSize: '14px',
      textDecoration: 'none',
      lineHeight: '24px',
      color: theme.palette.neutral[900]
    },
    overline: {
      fontFamily: 'inherit',
      fontSize: '10px',
      fontWeight: 400,
      color: theme.palette.neutral[600]
    },
    pxToRem: () => 'null'
  }
}
