import { Components } from '@mui/material/styles/components'

export default function InputBase(): Components['MuiInputBase'] {
  return {
    styleOverrides: {
      root: {
        '&:before': {
          display: 'none'
        }
      }
    }
  }
}
