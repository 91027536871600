import { Components } from '@mui/material/styles/components'

export default function Avatar(): Components['MuiAvatar'] {
  return {
    defaultProps: {
      variant: 'circular'
    },
    styleOverrides: {
      root: {
        height: '48px',
        width: '48px'
      },
      square: {
        borderRadius: 2
      }
    }
  }
}
