import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'

export default function CssBaseline(theme: Theme): Components['MuiCssBaseline'] {
  return {
    styleOverrides: {
      html: {
        h1: theme.typography.h1,
        h2: theme.typography.h2,
        h3: theme.typography.h3,
        h4: theme.typography.h4,
        h5: theme.typography.h5,
        h6: theme.typography.h6
      },
      body: {
        fontFamily: 'inherit',
        wordSpacing: '1px',
        webkitFontSmoothing: 'antialiased',
        mozOsxFontSmoothing: 'grayscale',
        fontVariant: 'auto',
        textRendering: 'auto',
        textSizeAdjust: '100%',
        webkitTextSizeAdjust: '100%',
        webkitTapHighlightColor: 'transparent',
        opacity: 1,
        overflowX: 'hidden',
        '.intercom-lightweight-app': {
          [theme.breakpoints.down('md')]: {
            zIndex: 998
          }
        },
        '#Coi-Renew': {
          [theme.breakpoints.down('md')]: {
            zIndex: 998
          }
        }
      }
    }
  }
}
