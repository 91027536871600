import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'

export default function Radio(theme: Theme): Components['MuiRadio'] {
  return {
    styleOverrides: {
      root: {
        padding: 0,
        '&:hover': {
          backgroundColor: 'transparent'
        },
        span: {
          svg: {
            width: '20px',
            fill: theme.palette.primary.main
          }
        }
      }
    }
  }
}
