import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'

export default function AppBar(theme: Theme): Components['MuiAppBar'] {
  return {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.neutral['000'],
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.palette.neutral[200]}`
      }
    }
  }
}
