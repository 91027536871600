import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'

export default function AccordionDetails(theme: Theme): Components['MuiAccordionDetails'] {
  return {
    styleOverrides: {
      root: {
        fontSize: '14px',
        padding: '16px 16px 0 0',
        color: theme.palette.neutral[900]
      }
    }
  }
}
