import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'

export default function ButtonGroup(theme: Theme): Components['MuiButtonGroup'] {
  return {
    styleOverrides: {
      root: {
        '&.MuiButtonGroup-segmented': {
          display: 'flex',
          width: '100%',
          gap: '8px',
          backgroundColor: theme.palette.secondary.main,
          borderRadius: '100px',
          padding: '4px',
          button: {
            padding: '0',
            height: '32px',
            width: '100%',
            maxWidth: '100%',
            border: 'none',
            borderTopLeftRadius: '32px !important',
            borderBottomLeftRadius: '32px !important',
            borderTopRightRadius: '32px !important',
            borderBottomRightRadius: '32px !important',
            '&.Mui-selected': {
              backgroundColor: theme.palette.primary.main
            },
            '&:not(.Mui-selected)': {
              '.MuiTypography-body1': {
                fontWeight: 400,
                color: theme.palette.neutral[900]
              }
            },
            '.MuiTypography-body1': {
              fontSize: '12px',
              fontWeight: 500,
              lineHeight: '16px',
              color: theme.palette.neutral['000']
            }
          }
        }
      }
    }
  }
}
