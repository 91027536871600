import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'

export default function Paper(theme: Theme): Components['MuiPaper'] {
  return {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        borderRadius: '16px',
        backgroundColor: theme.palette.neutral['000']
      }
    }
  }
}
