import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'

export default function Tabs(theme: Theme): Components['MuiTabs'] {
  return {
    styleOverrides: {
      root: {
        '.MuiTabs-flexContainer': {
          button: {
            color: theme.palette.neutral[900],
            textTransform: 'none',
            lineHeight: '24px',
            fontWeight: 500
          }
        },
        '&.MuiTabs-buttonsHover': {
          button: {
            '&:hover': {
              backgroundColor: theme.palette.neutral[100]
            }
          }
        }
      },
      vertical: {
        backgroundColor: theme.palette.neutral['000'],
        borderRadius: '0px',
        padding: '0',
        height: 'auto',
        minHeight: 'auto',
        '.MuiTabs-flexContainer': {
          button: {
            display: 'inline-flex',
            minHeight: '40px',
            height: '40px',
            fontFamily: 'inherit',
            textTransform: 'none',
            color: theme.palette.neutral[900],
            fontWeight: 400,
            fontSize: '16px',
            padding: '8px 12px',
            alignItems: 'start',
            '&.Mui-selected': {
              backgroundColor: 'transparent',
              color: theme.palette.primary.main,
              fontWeight: 600
            },
            '&:active': {
              backgroundColor: 'transparent'
            }
          }
        },
        span: {
          left: 0,
          width: '3px',
          borderRadius: '2px',
          backgroundColor: theme.palette.primary.main
        }
      }
    }
  }
}
