import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'

export default function Button(theme: Theme): Components['MuiButton'] {
  return {
    styleOverrides: {
      root: {
        fontFamily: 'inherit',
        borderRadius: 50,
        textTransform: 'none',
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 16,
        paddingBottom: 16,
        boxShadow: 'none',
        fontWeight: '500',
        fontSize: '16px',
        height: '58px',
        '&:disabled': {
          backgroundColor: theme.palette.neutral[100],
          color: theme.palette.neutral.main,
          border: `1px solid ${theme.palette.neutral[200]}`
        }
      },
      contained: {
        backgroundColor: 'primary.main',
        '&:hover': {
          backgroundColor: theme.palette.primary[600],
          boxShadow: 'none'
        },
        '&:active': {
          backgroundColor: theme.palette.primary[700]
        }
      },
      outlined: {
        border: `1px solid ${theme.palette.secondary[600]}`,
        color: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.secondary.main,
          border: `1px solid ${theme.palette.secondary.main}`
        },
        '&:active': {
          backgroundColor: theme.palette.secondary[600]
        }
      },
      text: {
        color: theme.palette.primary.main,
        borderRadius: 0,
        '&:hover': {
          backgroundColor: theme.palette.neutral[100]
        },
        '&:disabled': {
          color: theme.palette.neutral.main,
          border: 'none',
          backgroundColor: 'transparent'
        }
      }
    },
    variants: [
      {
        props: { variant: 'icon' },
        style: {
          width: '40px',
          height: '40px',
          minWidth: 'auto',
          padding: '0px',
          borderRadius: '100%',
          backgroundColor: theme.palette.neutral['000'],
          '&:hover': {
            backgroundColor: theme.palette.secondary.main
          },
          '&:active': {
            backgroundColor: theme.palette.secondary[600]
          },
          '&.Mui-disabled': {
            border: 'none',
            backgroundColor: theme.palette.neutral[100],
            svg: {
              fill: theme.palette.neutral[400]
            }
          }
        }
      },
      {
        props: { variant: 'iconOutlined' },
        style: {
          border: `1px solid ${theme.palette.neutral[200]}`,
          width: '40px',
          height: '40px',
          minWidth: 'auto',
          padding: '0px',
          borderRadius: '100%',
          backgroundColor: theme.palette.neutral['000'],
          '&:hover': {
            backgroundColor: theme.palette.secondary.main
          },
          '&:active': {
            backgroundColor: theme.palette.secondary[600]
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.neutral[100],
            svg: {
              fill: theme.palette.neutral[400]
            }
          }
        }
      },
      {
        props: { variant: 'ghost' },
        style: {
          backgroundColor: 'transparent',
          border: 'none',
          padding: 0,
          margin: 0,
          minWidth: 'auto',
          minHeight: 'auto',
          height: 'auto',
          width: 'auto',
          borderRadius: 0,
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }
      }
    ]
  }
}
