import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'
import Chevron from '../../icons/Chevron'

export default function AccordionSummary(theme: Theme): Components['MuiAccordionSummary'] {
  return {
    defaultProps: {
      expandIcon: Chevron({ width: '20px', height: '20px' })
    },
    styleOverrides: {
      root: {
        fontSize: '14px',
        padding: '16px 2px',
        borderBottom: `1px solid ${theme.palette.neutral[200]}`,
        '&.Mui-expanded': {
          minHeight: 0,
          '.MuiAccordionSummary-expandIconWrapper': {
            i: {
              color: theme.palette.neutral[900]
            }
          }
        },
        '.MuiAccordionSummary-expandIconWrapper': {
          i: {
            fontSize: '20px',
            color: theme.palette.neutral.main
          }
        }
      },
      content: {
        margin: 0,
        color: theme.palette.neutral[900],
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 500,
        '&.Mui-expanded': {
          margin: 0
        }
      }
    }
  }
}
