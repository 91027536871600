import { Theme } from '@mui/material/styles/createTheme'
import { Components } from '@mui/material/styles/components'
import EmptyStar from '../../icons/EmptyStar'
import Star from '../../icons/Star'

export default function Rating(theme: Theme): Components['MuiRating'] {
  return {
    defaultProps: {
      emptyIcon: EmptyStar({ width: '14px', height: '14px' }),
      icon: Star({ width: '14px', height: '14px' })
    },
    styleOverrides: {
      root: {
        display: 'inline-flex',
        gap: '2px',
        fill: theme.palette.primary.main
      }
    }
  }
}
