const palette = {
  primary: {
    main: '#DB2357',
    100: '#FDD7D2',
    600: '#E0426F',
    700: '#AF1550'
  },
  secondary: {
    main: '#F6C8D5',
    100: '#fef6f5',
    600: '#FADFE7',
    700: '#B0738E',
    800: '#8E496E',
    900: '#752B5A'
  },
  tertiary: {
    main: '#7BBDC1',
    100: '#8DC6CA',
    200: '#DBEEEF',
    300: '#CAE5E6',
    600: '#69A2A6',
    700: '#578789',
    800: '#476C6E'
  },
  neutral: {
    main: '#68707C',
    light: '#ffffff',
    '000': '#ffffff',
    100: '#F3F4F6',
    200: '#DDE1E7',
    300: '#C1C7CF',
    400: '#A2A9B1',
    600: '#1E2732',
    900: '#000000'
  },
  success: {
    main: '#25D167',
    100: '#D4FDDA',
    200: '#A7FAAF',
    600: '#11BD53'
  },
  warning: {
    main: '#FFCC14',
    100: '#FFF9D0',
    200: '#FFF0A1',
    600: '#F1BD00'
  },
  error: {
    main: '#FF0000',
    100: '#FDD7D2',
    200: '#FFB399',
    600: '#E70000'
  },
  custom: {
    facebook: {
      main: '#4266B2',
      hover: '#314D85',
      focus: '#1C3A76'
    },
    google: '#fff',
    email: '#fff',
    twitter: {
      main: '#55ACEE',
      hover: '#3396E1',
      focus: '#177DCB'
    },
    instagram: '#fff'
  },
  text: {
    primary: '#000000',
    secondary: '#878E97'
  },
  divider: '#dde1e7'
}

export default palette
