import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'

export default function Divider(theme: Theme): Components['MuiDivider'] {
  return {
    styleOverrides: {
      root: {
        height: '1px',
        backgroundColor: theme.palette.neutral[200]
      }
    }
  }
}
