import { Theme } from '@mui/material/styles/createTheme'
import { Components } from '@mui/material/styles/components'

export default function Switch(theme: Theme): Components['MuiSwitch'] {
  return {
    styleOverrides: {
      root: {
        width: '40px',
        margin: 0,
        padding: 0,
        height: '24px',
        overflow: 'visible',
        '.MuiButtonBase-root.Mui-disabled + .MuiSwitch-track': {
          border: `1px solid ${theme.palette.neutral[200]}`,
          backgroundColor: theme.palette.neutral[100]
        },
        '.MuiSwitch-track': {
          borderRadius: '50px',
          border: `1px solid ${theme.palette.primary[100]}`,
          backgroundColor: theme.palette.neutral.light,
          '&.Mui-checked': {
            backgroundColor: theme.palette.primary[100]
          }
        },
        '.MuiButtonBase-root.Mui-checked + .MuiSwitch-track': {
          border: `1px solid ${theme.palette.primary[100]}`,
          backgroundColor: theme.palette.primary[100]
        },
        '.MuiButtonBase-root': {
          padding: '6px',
          '&.MuiSwitch-switchBase:hover': {
            backgroundColor: 'transparent'
          },
          '&.Mui-checked': {
            backgroundColor: 'none',
            transform: 'translateX(16px)',
            '.MuiSwitch-thumb': {
              backgroundColor: theme.palette.primary.main
            }
          },
          '.MuiSwitch-thumb': {
            backgroundColor: theme.palette.secondary[600],
            height: '12px',
            width: '12px',
            borderRadius: '100%',
            boxShadow: 'none'
          },
          '&.Mui-disabled': {
            '.MuiSwitch-thumb': {
              backgroundColor: theme.palette.neutral[200]
            }
          }
        }
      }
    }
  }
}
