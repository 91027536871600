import { Components } from '@mui/material/styles/components'

export default function Container(): Components['MuiContainer'] {
  return {
    styleOverrides: {
      root: {
        '&.MuiContainer-maxWidthXl': {
          paddingLeft: '16px',
          paddingRight: '16px'
        }
      }
    }
  }
}
