import { Components } from '@mui/material/styles/components'

export default function Select(): Components['MuiSelect'] {
  return {
    defaultProps: {
      variant: 'filled'
    },
    styleOverrides: {
      root: {},
      filled: {}
    }
  }
}
