import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'

export default function Slider(theme: Theme): Components['MuiSlider'] {
  return {
    defaultProps: {
      size: 'small'
    },
    styleOverrides: {
      root: {
        '.MuiSlider-rail': {
          height: '2px',
          backgroundColor: theme.palette.neutral[100]
        },
        '.MuiSlider-mark': {
          position: 'absolute',
          top: '35%',
          width: '8px',
          height: '8px',
          backgroundColor: theme.palette.neutral[100],
          borderRadius: '100%',
          opacity: 1,
          '&.MuiSlider-markActive': {
            backgroundColor: theme.palette.primary.main
          }
        },
        '.MuiSlider-markLabel': {
          userSelect: 'none',
          top: '26px',
          fontSize: '12px',
          lineHeight: '16px',
          color: theme.palette.neutral[900]
        },
        '.MuiSlider-thumb': {
          width: '20px',
          height: '20px',
          '&:hover': {
            boxShadow: 'none'
          },
          '&.Mui-active': {
            boxShadow: 'none'
          },
          '&.Mui-focusVisible': {
            boxShadow: 'none'
          },
          '.MuiSlider-valueLabel': {
            position: 'absolute',
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            userSelect: 'none',
            '&.MuiSlider-valueLabel-top': {
              borderRadius: '8px',
              top: '-10px',
              backgroundColor: theme.palette.secondary[600],
              width: 'min-content',
              padding: '4px 8px',
              height: '24px',
              opacity: 0,
              transition: 'transform 150ms cubic-bezier(0.2, 0, 0.2, 1) 0ms',
              '&::after': {
                position: 'absolute',
                content: '""',
                left: '50%',
                transform: 'translate(-50%, 160%)',
                height: 0,
                width: 0,
                borderColor: 'transparent',
                borderStyle: 'solid',
                borderWidth: '5px',
                borderTopColor: theme.palette.secondary[600]
              },
              '&.Mui-active': {
                transform: 'translateY(-100%) scale(1)',
                opacity: 1
              }
            },
            '&.MuiSlider-valueLabel-bottom': {
              top: '26px',
              backgroundColor: theme.palette.neutral['000'],
              width: '50px',
              height: '24px',
              opacity: 0,
              transition: '0.1s',
              '&.Mui-active': {
                opacity: 1
              }
            },
            '.MuiTypography-body1': {
              color: theme.palette.neutral[900],
              fontFamily: 'inherit',
              fontSize: '12px',
              lineHeight: '16px'
            }
          }
        }
      }
    }
  }
}
