import { Components } from '@mui/material/styles/components'
import { Theme } from '@mui/material/styles/createTheme'

export default function Accordion(theme: Theme): Components['MuiAccordion'] {
  return {
    styleOverrides: {
      root: {
        marginTop: '1px',
        boxShadow: 'none',
        '&:active': {
          backgroundColor: theme.palette.neutral[100]
        },
        '&.Mui-expanded': {
          margin: 0
        }
      }
    }
  }
}
