import { Theme } from '@mui/material/styles/createTheme'
import { Components } from '@mui/material/styles/components'
import {
  Avatar,
  Button,
  Chip,
  Tabs,
  TextField,
  InputBase,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Rating,
  CssBaseLine,
  ButtonBase,
  Divider,
  Switch,
  Select,
  Slider,
  AppBar,
  ButtonGroup,
  Container,
  Breadcrumbs,
  Paper,
  Radio,
  Typography
  // Autocomplete
} from './components'

export default function getOverrides(theme: Theme): Components {
  return {
    MuiButtonBase: ButtonBase(),
    MuiCssBaseline: CssBaseLine(theme),
    MuiRating: Rating(theme),
    MuiAccordion: Accordion(theme),
    MuiAccordionSummary: AccordionSummary(theme),
    MuiAccordionDetails: AccordionDetails(theme),
    MuiAvatar: Avatar(),
    MuiButton: Button(theme),
    MuiChip: Chip(theme),
    MuiTabs: Tabs(theme),
    MuiTextField: TextField(theme),
    MuiInputBase: InputBase(),
    MuiDivider: Divider(theme),
    MuiSwitch: Switch(theme),
    MuiSlider: Slider(theme),
    MuiAppBar: AppBar(theme),
    MuiButtonGroup: ButtonGroup(theme),
    MuiContainer: Container(),
    MuiBreadcrumbs: Breadcrumbs(theme),
    MuiPaper: Paper(theme),
    MuiRadio: Radio(theme),
    MuiSelect: Select(),
    MuiTypography: Typography()
    // MuiAutocomplete: Autocomplete(theme)
  }
}
